import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u004e\u006f\u0020\u0073\u0065\u0020\u0065\u006e\u0063\u0075\u0065\u006e\u0074\u0072\u0061\u006e\u0020\u0063\u006f\u0069\u006e\u0063\u0069\u0064\u0065\u006e\u0063\u0069\u0061\u0073",
        messages: {
            required: '\u0052\u0065\u006c\u006c\u0065\u006e\u0065\u0020\u0065\u006c\u0020\u0063\u0061\u006d\u0070\u006f',
            phone: {
                incorrect: '\u004e\u00fa\u006d\u0065\u0072\u006f\u0020\u0064\u0065\u0020\u0074\u0065\u006c\u0065\u0066\u006f\u006e\u006f\u0020\u0069\u006e\u0063\u006f\u0072\u0072\u0065\u0063\u0074\u006f'
            },
            email: {
                incorrect: '\u0046\u006f\u0072\u006d\u0061\u0074\u006f\u0020\u0064\u0065\u0020\u0064\u0069\u0072\u0065\u0063\u0063\u0069\u00f3\u006e\u0020\u0069\u006e\u0063\u006f\u0072\u0072\u0065\u0063\u0074\u006f',
                symbolNotFound: '\u004c\u0061\u0020\u0064\u0069\u0072\u0065\u0063\u0063\u0069\u00f3\u006e\u0020\u0064\u0065\u0062\u0065\u0020\u0063\u006f\u006e\u0074\u0065\u006e\u0065\u0072\u0020\u0065\u006c\u0020\u0063\u0061\u0072\u0061\u0063\u0074\u0065\u0072\u0020\u0022\u0040\u0022\u002e\u0020\u0041\u0020\u006c\u0061\u0020\u0064\u0069\u0072\u0065\u0063\u0063\u0069\u00f3\u006e\u0020\u006c\u0065\u0020\u0066\u0061\u006c\u0074\u0061\u0020\u0065\u006c\u0020\u0073\u00ed\u006d\u0062\u006f\u006c\u006f\u0020\u0022\u0040\u0022',
                addressIsNotFull: '\u0049\u006e\u0074\u0072\u006f\u0064\u0075\u007a\u0063\u0061\u0020\u006c\u0061\u0020\u0070\u0061\u0072\u0074\u0065\u0020\u0064\u0065\u0020\u006c\u0061\u0020\u0064\u0069\u0072\u0065\u0063\u0063\u0069\u00f3\u006e\u0020\u0064\u0065\u0073\u0070\u0075\u00e9\u0073\u0020\u0064\u0065\u006c\u0020\u0063\u0061\u0072\u0061\u0063\u0074\u0065\u0072\u0020\u0022\u0040\u0022\u002e\u0020\u004c\u0061\u0020\u0064\u0069\u0072\u0065\u0063\u0063\u0069\u00f3\u006e\u0020\u006e\u006f\u0020\u0065\u0073\u0074\u00e1\u0020\u0063\u006f\u006d\u0070\u006c\u0065\u0074\u0061'
            },
            password: {
                minLength: '\u0045\u006c\u0020\u006e\u00fa\u006d\u0065\u0072\u006f\u0020\u006d\u00ed\u006e\u0069\u006d\u006f\u0020\u0064\u0065\u0020\u0063\u0061\u0072\u0061\u0063\u0074\u0065\u0072\u0065\u0073\u0020\u0070\u0065\u0072\u006d\u0069\u0074\u0069\u0064\u006f\u0020\u0065\u0073 6. \u0041\u0068\u006f\u0072\u0061 %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u004c\u0061\u0073\u0020\u0063\u006f\u006e\u0074\u0072\u0061\u0073\u0065\u00f1\u0061\u0073\u0020\u0069\u006e\u0067\u0072\u0065\u0073\u0061\u0064\u0061\u0073\u0020\u006e\u006f\u0020\u0063\u006f\u0069\u006e\u0063\u0069\u0064\u0065\u006e'
            },
            ofertaAgreement: {
                notChecked: '\u0043\u006f\u006e\u0066\u0069\u0072\u006d\u0065\u0020\u0073\u0075\u0020\u0061\u0063\u0065\u0070\u0074\u0061\u0063\u0069\u00f3\u006e\u0020\u0064\u0065\u0020\u006c\u0061\u0073\u0020\u0072\u0065\u0067\u006c\u0061\u0073\u002e'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});